import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Api from "../../helpers/Api";
import StatusList from "./status.list";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Button,
  TextField,
  Avatar,
} from "@mui/material";
import useShareAndAssign from "../ResourceAccessControl/shareAndAssign.js";

import StandardContainer from "../styled/generic/StandardContainer.jsx";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded.jsx";
import HorizBox from "../styled/generic/HorizBox.jsx";
import FormBox from "../styled/generic/FormBox.jsx";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions.jsx";
import { BiPlus } from "react-icons/bi";
import LibraryImport from "../OfferingsAndProducts/Library.Import.js";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  listStyle: {
    padding: "1rem",
    paddingBottom: "2rem",
    paddingTop: 0,
    minWidth: "17rem",
    width: "90vw",
    maxWidth: "32rem",
  },
  centerDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: "2rem 0",
    paddingTop: "3.5rem",
  },

  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },

  margin: {
    margin: "1rem",
  },

  col: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: "1rem",
    textAlign: "center",
    fontSize: "1.2rem",
    minWidth: "17rem",
  },
  createBtn: {
    paddingLeft: "1rem",
    padding: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    maxWidth: "6rem",
  },
  createBtnPaper: {
    maxWidth: "6rem",
    margin: "1rem",
  },
  bgColor: {
    backgroundColor: "#64b5f6",
    color: "white",
  },
  text: {
    fontSize: 20,
    fontWeight: "bold",
    margin: "1rem 1rem 1rem 0",
  },
  header: {
    "& p": {
      fontSize: "1rem",
    },
  },
  categoryAndLocationTag: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    padding: "0px 20px 10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  categoryOrLocationCont: {
    width: "45%",
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "15px",
    },
  },
  linkTextCont: {
    fontSize: "15px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  shareCont: {
    padding: "5px 10px",
    marginTop: "20px",
  },
}));

export default function IssueTemplateEdit(props) {
  console.log("template", props);
  const classes = useStyles();
  console.log('props=', props)
  const { template, setTemplate, setLoading } = props;
  const DateNow = new Date();
  const [lastUpdated, setLastUpdated] = useState(DateNow);
  const [library, setLibrary] = useState(template.library);
  const history = useHistory();
  const oldtitle = template?.title ? template.title : "";
  const oldFinalStates = template.finalStates || [];
  const oldStartState = template.startState ? template.startState : null;
  const [title, setTitle] = useState(oldtitle);
  const [open, setOpen] = useState(false);
  const [statusIds, setStatusIds] = useState([]);
  const [statusDict, setStatusDict] = useState({});
  const [startState, setStartState] = useState(oldStartState);
  const [finalStates, setFinalStates] = useState(oldFinalStates);
  const [libraryImportModel, setLibraryImportModel] = useState(false);

  const [form, setForm] = useState(template?.form);

  const [isCRM, setIsCRM] = useState(template?.isCRM);

  const [isPrivate, setPrivate] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const defaultHeaders = [
    "Title",
    "Assigned",
    "Due Date",
    "Priority",
    "Status",
    "Author",
  ];
  const [headers, setHeaders] = useState(
    template?.issueHeaders || defaultHeaders
  );

  const [configList, setConfigList] = useState(template?.graphConfigs || []);

  const fileReducer = useSelector((state) => state.file);
  const { createdFileIds } = fileReducer;

  const shareBtn = <div className={classes.linkTextCont}> Manage Access</div>;

  const { ShareButton, ShareDrawer, AssignButton, AssigneDrawer, AssignView } =
    useShareAndAssign({
      initShared: null,
      initAssigned: null,
      resourceId: template._id,
      resourceName: "IssueTemplate",
      customShareBtn: shareBtn,
      customAssignBtn: null,
    });

  const dispatch = useDispatch();
  const updateApi = async () => {
    let updateObj = {
      _id: template?._id,
      title,
      pipeline: statusIds,
      issueHeaders: headers,
      graphConfigs: configList,
      finalStates: finalStates,
      startState: startState,
      library: library._id,
      hiddenHeaders: hiddenHeaders,
      displayPicture: createdFileIds ? createdFileIds[0] : null,
      hiddenCheckeds: hiddenChecked,
      isCRM: isCRM,
    };

    await Api.post("issue/template/update", updateObj)
      .then((res) => {
        if (res?.data) {
          setTemplate(res?.data);
        }
      })
      .catch((err) => {
        console.log("error in issue.template.edit.js as :", err);
      });
  };

  const [hiddenHeaders, setHiddenHeaders] = useState(
    template?.hiddenHeaders || []
  );

  const [hiddenChecked, setHiddenCheckBox] = useState([]);

  console.log();

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={template?.title || "Edit Team Details"}
      appBarActions={
        <HorizBox>
          {ShareButton}

          <Button
            variant="contained"
            color="primary"
            disabled={title.length < 3}
            onClick={() => {
              if (headers?.length != 6) {
                dispatch({
                  type: "AddApiAlert",
                  payload: {
                    success: false,
                    message: "There should be exactly 6 ticket header items!",
                  },
                });
              } else {
                setLoading(true);
                updateApi("called from button");
                history.goBack();
              }
            }}
          >
            Save
          </Button>

          {ShareDrawer}
        </HorizBox>
      }
    >
      <StandardAppContainerRounded>
        <div style={{ padding: "1rem 0" }}>
          <Typography
            style={{
              fontSize: "1.06rem",
              fontFamily: "Inter",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            Edit team settings like states of ticket (according to your
            pipeline), manage access, add additional question in form if
            required
          </Typography>
          <div
            style={{
              fontSize: "1.06rem",
              fontFamily: "Inter",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            Click{" "}
            <span
              style={{
                backgroundColor: "rgba(135,131,120,0.15)",
                color: "#EB5757",
                fontSize: "1.06rem",
                fontFamily: "Inter",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Save
            </span>{" "}
            to save your changes.
          </div>
        </div>

        <FormBox label={"Enter Team Name"}>
          <TextField
            placeholder="Min 3 letters"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </FormBox>

        <FormBox
          label={"Select Library"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {library ? (
            <Chip
              color="primary"
              label={library.name}
              icon={<LibraryBooksIcon />}
              onDelete={() => setLibrary(null)}
              variant="filled"
              size="medium"
              avatar={<LibraryBooksIcon />}
            />
          ) : (
            <Button
              variant="contained"
              size="medium"
              onClick={() => setLibraryImportModel((prev) => !prev)}
              startIcon={<BiPlus />}
            >
              Import Library
            </Button>
          )}
        </FormBox>

        <Box sx={{ mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCRM}
                onChange={(_, checked) => setIsCRM(checked)}
                color="primary"
              />
            }
            label="Make this team a Sales team"
            color="primary"
          />
        </Box>
      </StandardAppContainerRounded>

      <StandardAppContainerRounded>
        <StatusList
          template={template}
          setTemplate={setTemplate}
          statusIds={statusIds}
          setStatusIds={setStatusIds}
          statusDict={statusDict}
          setStatusDict={setStatusDict}
          startState={startState}
          setStartState={setStartState}
          finalStates={finalStates}
          setFinalStates={setFinalStates}
          updateTemplate={updateApi}
        />
      </StandardAppContainerRounded>
      <LibraryImport
        importLibraryOpen={libraryImportModel}
        setImportLibraryOpen={setLibraryImportModel}
        importForIssues={true}
        library={library}
        setLibrary={(data) => {
          setLibrary(data[data.length - 1].library);
          console.log("library:", data[data.length - 1].library);
        }}
      />

      <StandardAppContainerRounded>
        <FormHeaderWithActions
          label="Assign Template"
          actions={<Box>{AssignButton}</Box>}
        />
        {AssigneDrawer}
        {AssignView}
      </StandardAppContainerRounded>
    </StandardContainer>
  );
}

// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import PropTypes from "prop-types";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import InputBase from "@material-ui/core/InputBase";
// import Typography from "@material-ui/core/Typography";
// import { useParams, useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
// import _ from "lodash";
// import DraggableStatusList from "../pipeline/DraggableStatusList";
// import Api from "../../helpers/Api";
// import ColorSelect from "../styled/color/color.select";
// import Paper from "@material-ui/core/Paper";
// import { StatusItem } from "../pipeline/status.item";
// import TocIcon from "@material-ui/icons/Toc";
// import StatusList from "./status.list";
// import TitleInput from "../styled/title.input";
// import { useDebounce } from "react-use";
// import ProfileAppbar from "../profile/profile.appbar";
// import { Box, Checkbox, Chip, FormControlLabel, FormGroup, Grid, Switch, TextField } from "@material-ui/core";
// import { Title } from "@material-ui/icons";
// import Question from "../form/question";
// import Form from "../form/form";
// import TimelineForm from "../form/TimelineForm";
// import Kanban from "../styled/Kanban/Kanban";

// import { createNewShare } from "../ResourceAccessControl/Api.call.js";
// import useShareAndAssign from "../ResourceAccessControl/shareAndAssign.js";

// import useShared from "../share/useShared";
// import SharedList from "../share/sharedList";
// import ShareIconBtn from "../share/share.icon.btn";

// import EditIcon from "@material-ui/icons/Edit";
// import ChooseHeader from "./ChooseHeader";
// import GraphConfigList from "./GraphConfigList";
// import UploadZone from "../file/Uploader/UploadZone";
// import DpUploadDialog from "../file/Uploader/DpUploadDialog";
// import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
// import { mainRoles } from "../../helpers/contactHelper"

// const useStyles = makeStyles((theme) => ({
//   listStyle: {
//     padding: "1rem",
//     paddingBottom: "2rem",
//     paddingTop: 0,
//     minWidth: "17rem",
//     width: "90vw",
//     maxWidth: "32rem",
//   },
//   centerDiv: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "white",
//     padding: "2rem 0",
//     paddingTop: "3.5rem",
//   },

//   row: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "row",
//   },

//   center: {
//     justifyContent: "center",
//     alignItems: "center",
//   },

//   margin: {
//     margin: "1rem",
//   },

//   col: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "column",
//   },
//   input: {
//     margin: "1rem",
//     textAlign: "center",
//     fontSize: "1.2rem",
//     minWidth: "17rem",
//   },
//   createBtn: {
//     paddingLeft: "1rem",
//     padding: "0.5rem",
//     paddingTop: "0.25rem",
//     paddingBottom: "0.25rem",
//     maxWidth: "6rem",
//   },
//   createBtnPaper: {
//     maxWidth: "6rem",
//     margin: "1rem",
//   },
//   bgColor: {
//     backgroundColor: "#64b5f6",
//     color: "white",
//   },
//   text: {
//     fontSize: 20,
//     fontWeight: "bold",
//     margin: "1rem 1rem 1rem 0",
//   },
//   header: {
//     "& p": {
//       fontSize: "1rem",
//     },
//   },
//   categoryAndLocationTag: {
//     display: "flex",
//     justifyContent: "space-between",
//     width: "100%",
//     marginTop: "20px",
//     padding: "0px 20px 10px",
//     [theme.breakpoints.down("xs")]: {
//       flexDirection: "column",
//     },
//   },
//   categoryOrLocationCont: {
//     width: "45%",
//     minHeight: "300px",
//     [theme.breakpoints.down("xs")]: {
//       width: "100%",
//       marginBottom: "15px",
//     },
//   },
//   linkTextCont: {
//     fontSize: "15px",
//     fontWeight: "500",
//     color: theme.palette.primary.main,
//     cursor: "pointer"
//   },
//   shareCont: {
//     padding: "5px 10px",
//     marginTop: "20px",
//   },
// }));

// const searchParameter = [
//   {
//     entity: "User",
//     scop: [
//       {
//         type: "Global",
//         role: []
//       }
//     ]
//   },
//   {
//     entity: "Organization",
//     scop: [
//       {
//         type: "Global",
//         role: []
//       }
//     ]
//   },
//   {
//     entity: "Project",
//     scop: [
//       {
//         type: "Contact",
//         role: [...mainRoles]
//       }
//     ]
//   }
// ]

// export default function IssueTemplateEdit(props) {
//   const classes = useStyles();
//   const { template, setTemplate, setLoading, handleClose } = props;
//   const DateNow = new Date();
//   const [lastUpdated, setLastUpdated] = useState(DateNow);

//   const history = useHistory();

//   const oldtitle = template?.title ? template.title : "";
//   const oldFinalStates = template.finalStates || [];
//   const oldStartState = template.startState ? template.startState : null;
//   const [title, setTitle] = useState(oldtitle);
//   const [open, setOpen] = useState(false);
//   const [statusIds, setStatusIds] = useState([]);
//   const [statusDict, setStatusDict] = useState({});
//   const [startState, setStartState] = useState(oldStartState);
//   const [finalStates, setFinalStates] = useState(oldFinalStates);

//   const [form, setForm] = useState(template?.form);

//   const [isPrivate, setPrivate] = useState(false);
//   const { user, userProfile } = useSelector((state) => state.auth);
//   const userProfileId = user?.profile;

//   const defaultHeaders = [
//     "Title",
//     "Assigned",
//     "Due Date",
//     "Priority",
//     "Status",
//     "Author",
//   ];
//   const [headers, setHeaders] = useState(
//     template?.issueHeaders || defaultHeaders
//   );

//   const [configList, setConfigList] = useState(template?.graphConfigs || []);

//   const fileReducer = useSelector((state) => state.file);
//   const { createdFileIds } = fileReducer;

//   const theme = useTheme();

//   const shareBtn = <div className={classes.linkTextCont} > Manage Access</div>

//   const { sharedProfilesRole, ShareButton, ShareDrawer, SharedView, assignedProfiles, AssignButton, AssigneDrawer, AssignView } = useShareAndAssign({
//     initShared: null,
//     initAssigned: null,
//     resourceId: template._id,
//     resourceName: "IssueTemplate",
//     customShareBtn: shareBtn,
//     customAssignBtn: null
//   })

//   const dispatch = useDispatch();
//   const updateApi = async () => {
//     let updateObj = {
//       _id: template?._id,
//       title,
//       pipeline: statusIds,
//       issueHeaders: headers,
//       graphConfigs: configList,
//       finalStates: finalStates,
//       startState: startState,
//       // shared: sharedProps?.shared,
//       // assigned: sharedProps?.assigned,
//       hiddenHeaders: hiddenHeaders,
//       displayPicture: createdFileIds ? createdFileIds[0] : null,
//       hiddenCheckeds: hiddenChecked,
//     };
//     await Api.post("issue/template/update", updateObj)
//       .then((res) => {
//         if (res?.data) {
//           setTemplate(res?.data);
//         }
//       })
//       .catch((err) => {
//         console.log("error in issue.template.edit.js as :", err);
//       });
//   };

//   const [hiddenHeaders, setHiddenHeaders] = useState(template?.hiddenHeaders || []);
//   const hiddenHeaderOptions = [
//     "Description",
//     "Start Date",
//     "End Date",
//     "Priority",
//     "Ancestors",
//     "Projects",
//     "Organizations",
//     // "MaterialList",
//   ];

//   const hiddenCheckBox = [
//     "Bill List",
//     "Check List",
//   ];
//   const [hiddenChecked, setHiddenCheckBox] = useState([]);
//   const handleHiddenChange = (event, values) => {
//     const isChecked = event.target.checked;

//     if (isChecked) {
//       setHiddenCheckBox((prevSelected) => [...prevSelected, values]);
//     } else {
//       setHiddenCheckBox((prevSelected) =>
//         prevSelected.filter((item) => item !== values)
//       );
//     }
//   }

//   const handleHiddenHeader = (value, isChecked) => {
//     let newList = [...hiddenHeaders];
//     if (isChecked) {
//       newList.push(value);
//     } else {
//       newList = newList.filter((elt) => elt !== value);
//     }
//     setHiddenHeaders(newList);
//   };

//   const callbackAfterShare = async (response) => {
//     console.log(response)
//   }

//   return (
//     <div className={classes.centerDiv}>
//       <ProfileAppbar
//         name={template?.title || "Edit Team details"}
//         btns={
//           <>

//             {/* <ShareIconBtn
//                 curEntityId={user?._id}
//                 entity={"User"}
//                 callbackAfterShare={callbackAfterShare}
//                 resourceName={"IssueTemplate"}
//                 resourceId={template?._id}
//                 searchParameter={searchParameter}
//                 sharedProfilesRole={sharedProfilesRole}
//                 setSharedProfilesRole={setSharedProfilesRole}
//             /> */}
//             {title.length > 3?            <Button
//               variant="contained"
//               color="primary"
//               disabled={title.length < 3}
//               onClick={() => {
//                 if (headers?.length != 6) {
//                   dispatch({
//                     type: "AddApiAlert",
//                     payload: {
//                       success: false,
//                       message: "There should be exactly 6 ticket header items!",
//                     },
//                   });
//                 } else {
//                   setLoading(true);
//                   updateApi("called from button");
//                   history.goBack();
//                 }
//               }}
//             >
//               Save
//             </Button>:null}
//             {ShareButton}
//             {ShareDrawer}
//           </>
//         }
//       />
//       <Paper style={{ width: "90%" }} elevation={0}>
//         <div style={{ padding: "1rem 0" }}>

//           <Typography
//             style={{
//               fontSize: "1.06rem",
//               fontFamily: "Inter",
//               fontWeight: "500",
//               lineHeight: "1.5",
//             }}
//           >
//             Edit team settings like states of ticket (according to your pipeline), manage access, add additional question in form if required
//           </Typography>
//           <div
//             style={{
//               fontSize: "1.06rem",
//               fontFamily: "Inter",
//               fontWeight: "500",
//               lineHeight: "1.5",
//             }}
//           >
//             Click{" "}
//             <span
//               style={{
//                 backgroundColor: "rgba(135,131,120,0.15)",
//                 color: "#EB5757",
//                 fontSize: "1.06rem",
//                 fontFamily: "Inter",
//                 fontWeight: "500",
//                 lineHeight: "1.5",
//               }}
//             >
//               Save
//             </span>{" "}
//             to save your changes.
//           </div>
//         </div>
//         {/* {sharedProps?.shareDrawer}
//         {sharedProps?.assignedDialog} */}
//         <TextField
//           label="Team Name "
//           placeholder="Min 3 letters"
//           type="text"
//           variant="outlined"
//           margin="normal"
//           fullWidth
//           onChange={(e) => setTitle(e.target.value)}
//           value={title}
//         />

//         {/* <Box display="flex" flexDirection="column">
//           <Typography
//             style={{
//               fontSize: "2rem",
//               fontFamily: "Inter",
//               fontWeight: "500",
//               lineHeight: "1.5",
//             }}
//           >
//             Upload an icon for your template
//           </Typography>
//           <CustomFileUploadButton
//             showComponent={
//               <Button color="primary" variant="contained">
//                 Upload
//               </Button>
//             }
//             fileNum={1}
//             givenFileTypes={["image"]}
//             givenMaxSize={26214400}
//             closeFunCall={() => { }}
//           />
//         </Box> */}

//         {/* <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             overflowY: "hidden",
//             overflowX: "hidden",
//             marginBottom: "1rem",
//             "&::-webkit-scrollbar": {
//               display: "none",
//             },
//           }}
//         >
//           <ChooseHeader headers={headers} setHeaders={setHeaders} form={form} />
//         </div> */}
//         {/* <div>
//           <div className={classes.header}>
//             <p>Choose Fields To Show</p>
//             <Box
//               display="flex"
//               alignItems="center"
//               justifyContent="center"
//               flexDirection="column"
//               width="100%"
//             > */}

//               {/* {hiddenHeaderOptions.map((opt, index) => (
//                 <Box key={index} width="100%" display="flex" alignItems="center">
//                   <div>
//                     <Switch
//                       onChange={(evt, isChecked) => {
//                         handleHiddenHeader(opt, isChecked);
//                       }}
//                       color="primary"
//                       checked={hiddenHeaders?.includes(opt)}
//                     />

//                   </div>
//                   <div style={{ fontSize: "1rem" }}>{opt}</div>
//                 </Box>
//               ))} */}
//               {/* <Card style={{ display: 'flex', flexDirection: 'row' }}> */}
//               {/* {hiddenCheckBox.map((value) => (
//                 <Box key={value} width="100%" display="flex" alignItems="center" marginLeft="4%">
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={hiddenChecked.includes(value)}
//                         onChange={(e) => { handleHiddenChange(e, value) }}
//                         name={value}
//                         color="primary"
//                       />
//                     }
//                     label={value}
//                   />
//                 </Box>
//               ))} */}
//               {/* </Card> */}

//             {/* </Box>
//           </div>
//         </div> */}
//         {/* <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             marginBottom: "1rem",
//           }}
//         >
//           <GraphConfigList
//             configList={configList}
//             setConfigList={setConfigList}
//             form={form}
//           />
//         </div> */}
//         {/* <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "left",
//             overflowY: "hidden",
//             overflowX: "hidden",
//             marginBottom: "1rem",
//             "&::-webkit-scrollbar": {
//               display: "none",
//             },
//           }}
//         >
//           <Form form={form} setForm={setForm} />
//         </div> */}

//         <StatusList
//           template={template}
//           setTemplate={setTemplate}
//           statusIds={statusIds}
//           setStatusIds={setStatusIds}
//           statusDict={statusDict}
//           setStatusDict={setStatusDict}
//           startState={startState}
//           setStartState={setStartState}
//           finalStates={finalStates}
//           setFinalStates={setFinalStates}
//           updateTemplate={updateApi}
//         />

//       </Paper>

//       <Paper className={classes.shareCont}>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <Typography variant="h4" className={classes.shareTitle}>
//               Assign
//             </Typography>

//             <div>
//               {AssignButton}
//               {AssigneDrawer}
//             </div>
//           </div>
//           <div>{AssignView}</div>
//         </Paper>

//     </div>
//   );
// }
