const initialState = {
  selectedFilePaths: [],
  selectedFileDictionary: {},
  createdFileIds: [],
  fileDictionary: {},
  allFileIds: [],
  newFileIds: [],
  isUploading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AddFile":
      return {
        ...state,
        ...action.payload,
      };

    case "SetFiles":
      return {
        ...state,
        fileDictionary: {
          ...state.fileDictionary,
          ...action.payload,
        },
      };

    case "FileReset":
      return initialState;

    case "FileUploadReset":
      return {
        fileDictionary: state.fileDictionary,
        selectedFilePaths: [],
        selectedFileDictionary: {},
        createdFileIds: [],
        isUploading: false,
      };

      case "START_FILE_UPLOAD":
        return {
          ...state,
          isUploading: true,
        };
  
      case "COMPLETE_FILE_UPLOAD":
        return {
          ...state,
          isUploading: false,
        };

        case "ADD_SELECTED_FILE":
          return {
            ...state,
            selectedFilePaths: [...state.selectedFilePaths, action.payload]
          };
    
        case "REMOVE_SELECTED_FILE":
          return {
            ...state,
            selectedFilePaths: state.selectedFilePaths.filter(file => file !== action.payload)
          };
    

    default:
      return state;
  }
};
