import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {
  Box,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import ListShowAll from "./list.showAll";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import HorizBox from "../styled/generic/HorizBox";
import PopupMenuList from "../styled/generic/PopupMenuList";
import DrawerContainer from "../styled/generic/DrawerContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "23%",
    height: "230px",
    marginLeft: "20px",
    padding: "5px 15px 5px",
    borderRadius: "5px",
    "&:hover": {
      transform: "scale(1.03)",
    },
    [theme.breakpoints.down("md")]: {
      width: "31%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "48%",
      marginLeft: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  headerCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "510",
    textTransform: "capitalize",
  },
  rowCont: {
    width: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  expand: {
    textAlign: "center",
    padding: "5px 0px",
    color: "gray",
    marginTop: "17px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  paperCard: {
    marginBottom: "10px",
  },
  singleCardCont: {
    width: "100%",
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
  },
  infoCont: {
    width: "calc(100% - 40px)",
    overflowX: "hidden",
    "& h3": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
    },
  },

  svgSize: {
    display: "flex",
    height: "30px",
    width: "30px",
  },

  header: {
    textAlign: "center",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },

  topRow: {
    marginBottom: "1rem",
  },

  createBtn: {
    paddingLeft: "1rem",
    padding: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },

  createBtnPaper: {
    marginLeft: "2rem",
    alignSelf: "flex-end",
  },

  icon: {
    color: theme.palette.primary.main,
    marginRight: "4px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
}));

export default function ListCard(props) {
  const classes = useStyles();
  const {
    templateId,
    template,
    title,
    onDelete,
    issueIds,
    issueDictionary,
    displayPicture,
    icon,
    buttons,
    paper_card,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorPopper, setAnchorPopper] = useState(null);

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorPopper(anchorPopper ? null : e.currentTarget);
  };
  const openPopper = Boolean(anchorPopper);

  useEffect(() => {
    const issues = issueIds.map((issueId) => issueDictionary[issueId]);
    setData(issues);
  }, [issueIds.length]);

  const getData = async () => {
    // await Api.post("/issue/template/getDataFromList", {
    // 	listId: templateId,
    // }).then((res) => {
    // 	setData(res);
    // });
    // setLoading(false);
  };

  const handleMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    getData();
  }, [templateId]);

  const CoolLookingChip = ({ text1, text2, color, backgroundColor }) => {
    return (
      <Box
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: "8px",
          padding: "4px 8px",
          color: color,
        }}
      >
        <HorizBox spacing={1}>
          <Typography variant="subtitle2" style={{ color: color }}>
            {text1}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ fontWeight: "bold", color: color }}
          >
            {text2}
          </Typography>
        </HorizBox>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.10)",
        p: 1,
      }}
    >
      <BoxSpaceBetween>
        <HorizBox spacing={2}>
          <Avatar
            src={icon}
            variant="rounded"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
          <Typography variant="h6">{title || "Untitled"}</Typography>
        </HorizBox>
        <PopupMenuList
          appearContent={
            <IconButton color="primary" size="small">
              <MoreVertIcon />
            </IconButton>
          }
          actions={[
            {
              title: "Edit team",
              action: () => {
                history.push(`/issue/template/${templateId}/edit`);
              },
            },
            {
              title: "Delete team",
              action: () => {
                onDelete(templateId);
              },
            },
          ]}
        />
      </BoxSpaceBetween>
      <HorizBox spacing={1} sx={{ mt: 2 }}>
        <CoolLookingChip
          text1="Open: "
          text2={
            template?.openIssueCount
          }
          color="#b91c1c"
          backgroundColor="#fecaca"
        />
        <CoolLookingChip
          text1="Closed: "
          text2={
            template?.closedIssueCount
          }
          color="#65a30d"
          backgroundColor="#ecfccb"
        />
        <CoolLookingChip
          text1="Total Issues: "
          text2={template?.issueCount}
          color="#d97706"
          backgroundColor="#fef3c7"
        />
      </HorizBox>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <HorizBox sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              history.push(`/issue/template/${templateId}/view/${profileId}`);
            }}
          >
            View Tickets
          </Button>
        </HorizBox>
        <DrawerContainer title="Tickets" open={open} setOpen={setOpen}>
          <ListShowAll data={data} setOpen={setOpen} />
        </DrawerContainer>
      </Box>
    </Box>
  );
}

{
  /* <div className={classes.headerCont}>
        <Box display="flex" alignItems="center">
          <Avatar
            alt={title || "Untitled"}
            src={
              displayPicture
                ? displayPicture.thumbUrl
                  ? displayPicture.thumbUrl
                  : displayPicture.url
                : " "
            }
            style={{
              width: "30px",
              height: "30px",
              marginRight: "10px",
            }}
          />
          <h3 className={classes.title}>{title || "Untitled"}</h3>
        </Box>
        <MyPopOver
          closeOnClick={true}
          appearContent={
            <IconButton style={{ padding: "4px" }}>
              <MoreHorizIcon />
            </IconButton>
          }
          showContent={
            <div>
              <MenuList id="menu-list-grow">
                <MenuItem
                  onClick={() => {
                    history.push(`/issue/template/${templateId}/edit`);
                  }}
                >
                  <EditIcon className={classes.icon} /> Edit template
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDelete(templateId);
                  }}
                >
                  <DeleteIcon className={classes.icon} /> Delete template
                </MenuItem>
              </MenuList>
            </div>
          }
        />
      </div>
      <div
        className={classes.rowCont}
        onClick={() => {
          if (!loading) {
            history.push(`/issue/template/${templateId}/view/${profileId}`);
          }
        }}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                {data?.length > 4 ? (
                  <>
                    {data?.slice(0, 3).map((dataItem, index) => {
                      return (
                        <div key={index} className={classes.singleCardCont}>
                          <Avatar
                            alt={dataItem?.user?.displayName}
                            src={
                              dataItem?.user?.displayPicture?.thumbUrl ||
                              dataItem?.user?.displayPicture?.url
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          />
                          <div
                            style={{ marginLeft: "7px" }}
                            className={classes.infoCont}
                          >
                            <h3>{dataItem?.title}</h3>
                            <p>
                              {moment(dataItem?.createdAt).format(
                                "MMM Do YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {data.map((dataItem, index) => {
                      return (
                        <div key={index} className={classes.singleCardCont}>
                          <Avatar
                            alt={dataItem?.user?.displayName}
                            src={
                              dataItem?.user?.displayPicture?.thumbUrl ||
                              dataItem?.user?.displayPicture?.url
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          />
                          <div
                            style={{ marginLeft: "7px" }}
                            className={classes.infoCont}
                          >
                            <h3>{dataItem?.title}</h3>
                            <p>
                              {moment(dataItem?.createdAt).format(
                                "MMM Do YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <p
                style={{
                  fontSize: "14px",
                  color: "gray",
                  textAlign: "center",
                }}
              >
                No data attached with the list
              </p>
            )}
          </>
        )}
      </div>
      {data?.length > 4 ? (
        <div
          variant="caption"
          color="primary"
          className={classes.expand}
          onClick={() => {
            setOpen(true);
          }}
        >
          Show All ({data?.length - 3})
        </div>
      ) : null}
      <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
        <ListShowAll data={data} setOpen={setOpen} />
      </Drawer> */
}

// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { makeStyles } from "@material-ui/core/styles";
// import { useHistory, useLocation } from "react-router-dom";
// import Paper from "@material-ui/core/Paper";
// import Avatar from "@material-ui/core/Avatar";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import moment from "moment";
// import {
//   Box,
//   Drawer,
//   IconButton,
//   LinearProgress,
//   Menu,
//   MenuItem,
//   Typography,
//   Button,
// } from "@material-ui/core";
// import BugReportIcon from "@material-ui/icons/BugReport";
// import clsx from "clsx";
// import IssueSvg from "../../Assets/issue.svg";
// import MenuList from "@material-ui/core/MenuList";
// import Api from "../../helpers/Api";
// import IssueItemCard from "../profile/cards/issue.item.card";
// import ListPaperCard from "./list.item.card";
// import ListShowAll from "./list.showAll";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import Popper from "@material-ui/core/Popper";
// import Fade from "@material-ui/core/Fade";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import MyPopOver from "../styled/CommonComponents/MyPopOver";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "23%",
//     height: "230px",
//     marginLeft: "20px",
//     padding: "5px 15px 5px",
//     borderRadius: "5px",
//     "&:hover": {
//       transform: "scale(1.03)",
//     },
//     [theme.breakpoints.down("md")]: {
//       width: "31%",
//     },
//     [theme.breakpoints.down("sm")]: {
//       width: "48%",
//       marginLeft: "15px",
//     },
//     [theme.breakpoints.down("xs")]: {
//       width: "100%",
//       marginLeft: "0px",
//     },
//   },
//   headerCont: {
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     marginBottom: "5px",
//   },
//   title: {
//     fontSize: "18px",
//     fontWeight: "510",
//     textTransform: "capitalize",
//   },
//   rowCont: {
//     width: "100%",
//     overflowX: "hidden",
//     overflowY: "auto",
//     "&::-webkit-scrollbar": {
//       display: "none",
//     },
//     scrollbarWidth: "none",
//   },
//   expand: {
//     textAlign: "center",
//     padding: "5px 0px",
//     color: "gray",
//     marginTop: "17px",
//     cursor: "pointer",
//     "&:hover": {
//       textDecoration: "underline",
//     },
//   },
//   paperCard: {
//     marginBottom: "10px",
//   },
//   singleCardCont: {
//     width: "100%",
//     padding: "2px 0px",
//     display: "flex",
//     alignItems: "center",
//   },
//   infoCont: {
//     width: "calc(100% - 40px)",
//     overflowX: "hidden",
//     "& h3": {
//       fontSize: "15px",
//       fontWeight: "500",
//     },
//     "& p": {
//       fontSize: "12px",
//       fontWeight: "400",
//     },
//   },

//   svgSize: {
//     display: "flex",
//     height: "30px",
//     width: "30px",
//   },

//   header: {
//     textAlign: "center",
//   },

//   row: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//   },

//   column: {
//     display: "flex",
//     flexDirection: "column",
//   },

//   topRow: {
//     marginBottom: "1rem",
//   },

//   createBtn: {
//     paddingLeft: "1rem",
//     padding: "0.5rem",
//     paddingTop: "0.25rem",
//     paddingBottom: "0.25rem",
//   },

//   createBtnPaper: {
//     marginLeft: "2rem",
//     alignSelf: "flex-end",
//   },

//   icon: {
//     color: theme.palette.primary.main,
//     marginRight: "4px",
//   },
//   buttons: {
//     display: "flex",
//     justifyContent: "space-evenly",
//     alignItems: "center",
//   },
// }));

// export default function ListCard(props) {
//   const classes = useStyles();
//   const {
//     templateId,
//     title,
//     onDelete,
//     issueIds,
//     issueDictionary,
//     displayPicture,
//     icon,
//     buttons,
//     paper_card,
//   } = props;

//   const history = useHistory();
//   const dispatch = useDispatch();
//   const { profileId } = useParams();
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [anchorPopper, setAnchorPopper] = useState(null);

//   const handleClose = (e) => {
//     e.stopPropagation();
//     setAnchorEl(null);
//   };

//   const handleClick = (e) => {
//     setAnchorPopper(anchorPopper ? null : e.currentTarget);
//   };
//   const openPopper = Boolean(anchorPopper);

//   useEffect(() => {
//     const issues = issueIds.map((issueId) => issueDictionary[issueId]);
//     setData(issues);
//   }, [issueIds.length]);

//   const getData = async () => {
//     // await Api.post("/issue/template/getDataFromList", {
//     // 	listId: templateId,
//     // }).then((res) => {
//     // 	setData(res);
//     // });
//     // setLoading(false);
//   };

//   const handleMenu = (e) => {
//     e.stopPropagation();
//     setAnchorEl(e.currentTarget);
//   };

//   useEffect(() => {
//     getData();
//   }, [templateId]);

//   return (
//     <>
//       <Paper className={classes.root} elevation={2} square>
//         <div className={classes.headerCont}>
//           <Box display="flex" alignItems="center">
//             <Avatar
//               alt={title || "Untitled"}
//               src={
//                 displayPicture
//                   ? displayPicture.thumbUrl
//                     ? displayPicture.thumbUrl
//                     : displayPicture.url
//                   : " "
//               }
//               style={{
//                 width: "30px",
//                 height: "30px",
//                 marginRight: "10px",
//               }}
//             />
//             <h3 className={classes.title}>{title || "Untitled"}</h3>
//           </Box>
//           <MyPopOver
//             closeOnClick={true}
//             appearContent={
//               <IconButton style={{ padding: "4px" }}>
//                 <MoreHorizIcon />
//               </IconButton>
//             }
//             showContent={
//               <div>
//                 <MenuList id="menu-list-grow">
//                   <MenuItem
//                     onClick={() => {
//                       history.push(`/issue/template/${templateId}/edit`);
//                     }}
//                   >
//                     <EditIcon className={classes.icon} /> Edit template
//                   </MenuItem>
//                   <MenuItem
//                     onClick={() => {
//                       onDelete(templateId);
//                     }}
//                   >
//                     <DeleteIcon className={classes.icon} /> Delete template
//                   </MenuItem>
//                 </MenuList>
//               </div>
//             }
//           />
//         </div>
//         <div
//           className={classes.rowCont}
//           onClick={() => {
//             if (!loading) {
//               history.push(`/issue/template/${templateId}/view/${profileId}`);
//             }
//           }}
//         >
//           {loading ? (
//             <LinearProgress />
//           ) : (
//             <>
//               {data?.length > 0 ? (
//                 <>
//                   {data?.length > 4 ? (
//                     <>
//                       {data?.slice(0, 3).map((dataItem, index) => {
//                         return (
//                           <div key={index} className={classes.singleCardCont}>
//                             <Avatar
//                               alt={dataItem?.user?.displayName}
//                               src={
//                                 dataItem?.user?.displayPicture?.thumbUrl ||
//                                 dataItem?.user?.displayPicture?.url
//                               }
//                               style={{
//                                 width: "30px",
//                                 height: "30px",
//                               }}
//                             />
//                             <div
//                               style={{ marginLeft: "7px" }}
//                               className={classes.infoCont}
//                             >
//                               <h3>{dataItem?.title}</h3>
//                               <p>
//                                 {moment(dataItem?.createdAt).format(
//                                   "MMM Do YYYY"
//                                 )}
//                               </p>
//                             </div>
//                           </div>
//                         );
//                       })}
//                     </>
//                   ) : (
//                     <>
//                       {data.map((dataItem, index) => {
//                         return (
//                           <div key={index} className={classes.singleCardCont}>
//                             <Avatar
//                               alt={dataItem?.user?.displayName}
//                               src={
//                                 dataItem?.user?.displayPicture?.thumbUrl ||
//                                 dataItem?.user?.displayPicture?.url
//                               }
//                               style={{
//                                 width: "30px",
//                                 height: "30px",
//                               }}
//                             />
//                             <div
//                               style={{ marginLeft: "7px" }}
//                               className={classes.infoCont}
//                             >
//                               <h3>{dataItem?.title}</h3>
//                               <p>
//                                 {moment(dataItem?.createdAt).format(
//                                   "MMM Do YYYY"
//                                 )}
//                               </p>
//                             </div>
//                           </div>
//                         );
//                       })}
//                     </>
//                   )}
//                 </>
//               ) : (
//                 <p
//                   style={{
//                     fontSize: "14px",
//                     color: "gray",
//                     textAlign: "center",
//                   }}
//                 >
//                   No data attached with the list
//                 </p>
//               )}
//             </>
//           )}
//         </div>
//         {data?.length > 4 ? (
//           <div
//             variant="caption"
//             color="primary"
//             className={classes.expand}
//             onClick={() => {
//               setOpen(true);
//             }}
//           >
//             Show All ({data?.length - 3})
//           </div>
//         ) : null}
//         <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
//           <ListShowAll data={data} setOpen={setOpen} />
//         </Drawer>
//       </Paper>
//     </>
//   );
// }
