import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../../helpers/Api";
import _ from "lodash";
import arrayToReducer from "../../helpers/arrayToReducer";
import IssueTemplateList from "./issue.template.list";
import useProfileIssueData from "./useProfileIssueData";
import useGetProfile from "../profile/useGetProfile";
import useGetTemplates from "./useGetTemplates";
import AddIcon from "@material-ui/icons/Add";
import StandardContainer from "../styled/generic/StandardContainer";
import { LoadingButton } from "@mui/lab";
import { getWalletDataByProfileId } from "../finance2o/accounts/api.call";
import ProfileIssueKanbanView from "./profile.issue.kanban.view";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "16px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  invoiceEditBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  loadingSty: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    paddingBottom: "10px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  btnSty: {
    marginRight: "10px",
  },

  row: {
    flex: "1",
    display: "flex",
  },
  topContainer: {
    padding: "1rem 3rem",
  },
  header: {
    color: "#00345D",
    fontSize: "1.6rem",
  },
  subHeader: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontWeight: "500",
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& h3": {
        marginBottom: "1rem",
      },
    },
  },
  // templateButtons: {
  //   display: "flex",
  //   justifyContent: "space-around",
  //   alignItems: "center",
  // },
  button: {
    textTransform: "none",
    background: "#f5f6f8",
    marginRight: "1rem",
  },
  icon: {
    color: "#1684ea",
  },
}));

export default function ProfileIssues(props) {
  const classes = useStyles();
  const { profileId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const user = auth?.user;
  const [value, setValue] = React.useState(1);
  const [openChoose, setOpenChoose] = useState(false);
  const [openCloneDialog, setOpenCloneDialog] = useState(false);
  const [refreshTemplateData, setRefreshTemplateData] = useState(false);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [walletData, setWalletData] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const profile = useGetProfile(profileId);
  const {
    templateIds,
    setTemplateIds,
    templateDictionary,
    setTemplateDictionary,
  } = useGetTemplates(profileId, refreshTemplateData);
  const {
    issueIds,
    setIssueIds,
    issueDictionary,
    setIssueDictionary,
    loading,
    totalIssues,
    refetchIssues,
    currentPage,
    totalPages,
  } = useProfileIssueData(profileId);

  const updateTemplateArray = (data) => {
    const { newDict, idArr } = arrayToReducer(data);
    const newSet = new Set([...templateIds, ...idArr]);
    const newArr = Array.from(newSet);
    setTemplateIds(newArr);
    setTemplateDictionary({
      ...templateDictionary,
      ...newDict,
    });
  };

  const importTemplate = async (arr) => {
    if (arr?.length > 0) {
      const templateIds = arr.map((e) => e?._id);
      const res = await Api.post("issue/template/import", {
        templateIds,
        profileId,
      });
      const data = res?.data;

      if (data) {
        setRefreshTemplateData(true);
      }
    }
  };

  useEffect(() => {
    if (profileId) {
      getWalletDataByProfileId({ profileId: profileId })
        .then(async (data1) => {
          setWalletData(data1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  //function to create new template
  const createTemplate = async () => {
    setCreatingTemplate(true);
    let sharedSet = new Set([profileId, user?.profile]);
    var shared = Array.from(sharedSet);

    const res = await Api.post("issue/template/create", {
      createdIn: profileId,
      shared,
      library: walletData?.defaultLibrary,
      creator: user?.profile,
      managers: [user?.profile],
    });

    const data = res?.data;
    if (data?._id) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Team created successfully",
        },
      });

      var path = "/issue/template/" + data?._id + "/edit";
      history.push(path);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Team create failed",
        },
      });
    }
    setCreatingTemplate(false);
  };

  const cloneTemplates = async (checkedTemplateIds) => {
    let templateCount = checkedTemplateIds?.length;
    let newTemplates = [];
    while (templateCount > 0) {
      newTemplates.push({
        profile: profileId,
        shared: [profileId],
        managers: [user?.profile],
      });
      templateCount--;
    }
    const res = await Api.post("issue/template/clone", {
      templateIds: checkedTemplateIds,
      newTemplates,
      profileId,
    });
    const data = res?.data;
    if (data?.clonedTemplates) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Template clone successful!",
        },
      });
      setRefreshTemplateData(true);
    }
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Project Management"
      loading={loading}
      appBarActions={
        <Box>
          {/* <Button
            variant="contained"
            startIcon={<ImportExportIcon className={classes.icon} x />}
            onClick={() => setOpenCloneDialog(true)}
            className={classes.btnSty}
          >
            Import
          </Button>
          <CloneTemplateDialog
            cloneTemplates={cloneTemplates}
            open={openCloneDialog}
            setOpen={setOpenCloneDialog}
            setOpenChoose={setOpenChoose}
          />
          <Choose
            open={openChoose}
            setOpen={setOpenChoose}
            parentModelName={"Template"}
            multiple={true}
            disableModelChange={false}
            placeHolder={"Import Template"}
            onSelected={(arr) => {
              importTemplate(arr);
            }}
          /> */}
          <LoadingButton
            variant="contained"
            startIcon={<AddIcon />}
            loading={creatingTemplate}
            onClick={() => {
              createTemplate();
            }}
          >
            New Team
          </LoadingButton>
        </Box>
      }
    >
      <IssueTemplateList
        profileId={profileId}
        templateIds={templateIds}
        templateDictionary={templateDictionary}
        setTemplateIds={setTemplateIds}
        setTemplateDictionary={setTemplateDictionary}
        issueIds={issueIds}
        setIssueIds={setIssueIds}
        issueDictionary={issueDictionary}
        setIssueDictionary={setIssueDictionary}
        loading={loading}
        totalIssues={totalIssues}
        refetchIssues={refetchIssues}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <ProfileIssueKanbanView
        profileId={profileId}
      />
    </StandardContainer>
  );
}

// <>
//   <ProfileAppbar
//     profile={profile}
//     name={"WorkFlow & Issues "}
//     btns={
//       <div style={{ marginRight: "2vw" }}>
//         <YoutubeTuts
//           name={"Workflow"}
//           dialogTitle={"Project Management"}
//         />
//       </div>
//     }
//   />
//   <div
//     style={{
//       display: "flex",
//       gap: "7px",
//       marginBottom: "16px",
//     }}
//   >
//     <img src={IssueSvg} style={{ width: "3rem" }} />
//     <Typography variant="h3" style={{ fontWeight: "600" }}>
//       Project Management
//     </Typography>
//   </div>
//   <Typography
//     style={{
//       fontSize: "1.06rem",
//       fontFamily: "Inter",
//       fontWeight: "500",
//       lineHeight: "1.5",
//     }}
//   >
//     Create template to manage any process.
//   </Typography>
//   <Typography
//     style={{
//       fontSize: "1.06rem",
//       fontFamily: "Inter",
//       fontWeight: "500",
//       lineHeight: "1.5",
//     }}
//   >
//     Template contains form (similar to google form) and sharing/
//     notification settings
//   </Typography>

//   <div
//     style={{
//       fontSize: "1.06rem",
//       fontFamily: "Inter",
//       fontWeight: "500",
//       lineHeight: "1.5",
//     }}
//   >
//     Click{" "}
//     <span
//       style={{
//         backgroundColor: "rgba(135,131,120,0.15)",
//         color: "#EB5757",
//         fontSize: "1.06rem",
//         fontFamily: "Inter",
//         fontWeight: "500",
//         lineHeight: "1.5",
//       }}
//     >
//       + New
//     </span>{" "}
//     to create a new{" "}
//     <span
//       style={{
//         backgroundColor: "rgba(135,131,120,0.15)",
//         color: "#EB5757",
//         fontSize: "1.06rem",
//         fontFamily: "Inter",
//         fontWeight: "500",
//         lineHeight: "1.5",
//       }}
//     >
//       {value == 0
//         ? "Task Map"
//         : value == 1
//           ? "Template"
//           : "Issue"}
//     </span>{" "}
//     directly for this project.
//   </div>
//   <Tabs
//     value={value}
//     onChange={handleChange}
//     indicatorColor="primary"
//     textColor="primary"
//     variant="fullWidth"
//     aria-label="full width tabs example"
//     style={{ backgroundColor: "white" }}
//   >

//     <Tab
//       label=""
//       value={1}
//       {...a11yProps(1)}
//     />

//   </Tabs>
//   <TabPanel value={value} index={2}>
//     <IssueList
//       loading={loading}
//       profile={profile}
//       profileId={profileId}
//       totalIssues={totalIssues}
//       templateIds={templateIds}
//       templateDictionary={templateDictionary}
//       issueIds={issueIds}
//       issueDictionary={issueDictionary}
//     />
//   </TabPanel>
//   <TabPanel value={value} index={0}>
//     <IssueContractList
//       profileId={profileId}
//       projectArray={projectArray}
//     />
//   </TabPanel>
// </>
