import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../helpers/Api";
import ListCard from "./list.card";
import Choose from "../select/choose";
import { Button } from "@material-ui/core";
import arrayToReducer from "../../helpers/arrayToReducer";
import CloneTemplateDialog from "./template.clone.dialog";
import FormBox from "../styled/generic/FormBox";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    marginBottom: "20px",
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
}));
export default function TemplateCardList(props) {
  const { auth } = useSelector((state) => state);
  const user = auth?.user;
  const dispatch = useDispatch();
  const [openChoose, setOpenChoose] = useState(false);
  const [openCloneDialog, setOpenCloneDialog] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const {
    templateIds,
    templateDictionary,
    setTemplateIds,
    setTemplateDictionary,
    onDelete,
    issueIds,
    issueDictionary,
    grouped,
    profile,
  } = props;
  const profileId = profile?._id;


  const importTemplate = async (arr) => {
    if (arr?.length > 0) {
      const templateIds = arr.map((e) => e?._id);
      const res = await Api.post("issue/template/import", {
        templateIds,
        profileId,
      });
      const data = res?.data;

      if (data) {
        const { newDict, idArr } = arrayToReducer(data);
        const newSet = new Set([...idArr, ...templateIds]);
        const newArr = Array.from(newSet);
        setTemplateIds(newArr);
        setTemplateDictionary({
          ...templateDictionary,
          ...newDict,
        });
      }
    }
  };

  //issue->made of templates
  //template -> form -> questions
  //task:make a new template out of pre defined templates
  //template: population of pipeline,start state, final states, shared, managers, forms, profile, default title:"Untitled"
  //club all together
  //get all pipelines first,merge them in an array and send to backend

  const cloneTemplates = async (templateIds) => {
    let templateCount = templateIds?.length;
    let newTemplateIds = [],
      newTemplates = [];
    while (templateCount > 0) {
      newTemplates.push({
        profile: profileId,
        shared: [profileId],
        managers: [user?.profile],
      });
      templateCount--;
    }
    const res = await Api.post("issue/template/clone", {
      templateIds,
      newTemplates,
      profileId,
    });
    const data = res?.data;
    if (data?.clonedTemplates) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Template clone successful!",
        },
      });
    }
  };

  return (
    <FormBox label="Teams">
      <Grid container spacing={2} mt={1}>
        {templateIds.map((templateId, index) => {
          const template = templateDictionary[templateId];
          const title = templateDictionary[templateId]?.title;
          const displayPicture =
            templateDictionary[templateId]?.displayPicture || null;

          const issues = grouped[templateId] || [];
          const filterIssueIds = issues.map((issue) => issue?._id);
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ListCard
                key={index}
                template={template}
                templateId={templateId}
                title={title}
                onDelete={onDelete}
                issueIds={filterIssueIds}
                issueDictionary={issueDictionary}
                displayPicture={displayPicture}
              />
            </Grid>
          );
        })}
      </Grid>
    </FormBox>
  );
}

{
  /* <div>
        <Button
          style={{ textTransform: "none", borderRadius: "1.2rem" }}
          variant="outlined"
          color="primary"
          onClick={() => setOpenChoose(true)}
        >
          Import
        </Button>
        <Choose
          open={openChoose}
          setOpen={setOpenChoose}
          parentModelName={"Template"}
          multiple={true}
          disableModelChange={true}
          placeHolder={"Import Template"}
          onSelected={(arr) => {
            importTemplate(arr);
          }}
        />
      </div>
      <div>
        <Button
          style={{ textTransform: "none", borderRadius: "1.2rem" }}
          variant="outlined"
          color="primary"
          onClick={() => setOpenCloneDialog(true)}
        >
          Clone
        </Button>
        <CloneTemplateDialog
          cloneTemplates={cloneTemplates}
          open={openCloneDialog}
          setOpen={setOpenCloneDialog}
          profileId={profileId}
        />
      </div> */
}
