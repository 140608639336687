import React, { useEffect, useState } from "react";
import "../../App.css";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import { Box, Grid, MenuItem, Pagination } from "@mui/material";
import HorizBox from "../styled/generic/HorizBox";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Select from "../styled/generic/Select";
import LoadingHandler from "../styled/generic/LoadingHandler";
import CRMCard from "./issue.crm.card";
import TextField from "../styled/generic/TextField";
import { useDebounce } from "react-use";
import UserPicker from "../styled/generic/UserPicker";
import SimpleCard from "./issue.simple.card";
import useProfileIssueData from "./useProfileIssueData";

const ProfileIssueKanbanView = ({ profileId }) => {
  const dispatch = useDispatch();
  const {
    issueIds,
    issueDictionary,
    setIssueDictionary,
    loading,
    refetchIssues,
    totalIssues,
    currentPage,
    totalPages,
  } = useProfileIssueData(profileId);

  const [createdAtFilter, setCreatedAtFilter] = useState("ascending");
  const [statusFilters, setStatusFilters] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [labelFilters, setLabelFilters] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [searchOption, setSearchOption] = useState("Ticket Title");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateFilters, setTemplateFilters] = useState([]);

  useEffect(() => {
    fetchTemplateOptions();
    fetchLabelOptions();
  }, [profileId]);

  const fetchTemplateOptions = async () => {
    try {
      const res = await Api.post("/issue/template/getByProfile", {
        profileId: profileId,
      });
      if (res?.data) {
        setTemplateOptions(res.data);
        setTemplateFilters(res.data.map(template => template._id));
        // Assuming the first template's pipeline is used for status options
        if (res.data.length > 0) {
          setStatusOptions(res.data[0].pipeline);
          setStatusFilters(res.data[0].pipeline.map(status => status._id));
        }
      }
    } catch (error) {
      console.error("Error fetching template options:", error);
    }
  };

  const fetchLabelOptions = async () => {
    try {
      const res = await Api.post("get/categories/by-library", {
        profileId: profileId,
        type: 'Task'
      });
      if (res?.data) {
        setLabelOptions(res.data);
      }
    } catch (error) {
      console.error("Error fetching label options:", error);
    }
  };

  useDebounce(
    () => {
      refetchIssues(currentPage, {
        createdAtFilter,
        statusFilters: statusFilters?.length === 0 ? null : statusFilters,
        labelFilters: labelFilters?.length === 0 ? null : labelFilters,
        searchStr,
        searchOption,
        assignedUserProfileIds: selectedUsers.map((user) => user?.profile?._id),
        templateFilters: templateFilters?.length === 0 ? null : templateFilters,
      });
    },
    500,
    [searchStr, searchOption, createdAtFilter, statusFilters, labelFilters, selectedUsers, templateFilters]
  );

  return (
    <Box>
      <Box>
        <StandardAppContainerRounded>
          <HorizBox>
            <TextField
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              size="small"
              fullWidth={true}
              placeholder="Search for a ticket..."
              sx={{
                width: "350px",
              }}
            />
            <Select
              value={templateFilters}
              onChange={(e) => setTemplateFilters(e.target.value)}
              size="small"
              multiple={true}
              sx={{ width: "200px" }}
            >
              {templateOptions.map((template) => (
                <MenuItem key={template._id} value={template._id}>{template.title}</MenuItem>
              ))}
            </Select>
            <Select
              value={statusFilters}
              onChange={(e) => setStatusFilters(e.target.value)}
              size="small"
              multiple={true}
              sx={{ width: "200px" }}
            >
              {statusOptions.map((status) => (
                <MenuItem key={status._id} value={status._id}>{status.text}</MenuItem>
              ))}
            </Select>
            <Select
              value={labelFilters}
              onChange={(e) => setLabelFilters(e.target.value)}
              size="small"
              multiple={true}
              sx={{ width: "200px" }}
            >
              {labelOptions.map((label) => (
                <MenuItem key={label._id} value={label._id}>{label.name}</MenuItem>
              ))}
            </Select>
            <UserPicker
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              size="small"
              textFieldSx={{ width: "200px" }}
            />
          </HorizBox>
        </StandardAppContainerRounded>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StandardAppContainerRounded fullHeight={true}>
            <LoadingHandler loading={loading} fullHeight={true}>
              <Grid container spacing={1}>
                {issueIds.map((issueId) => {
                  const issue = issueDictionary[issueId];
                  
                  // Return null if issue template doesn't exist
                  if (!issue?.template?._id) return null;
                  
                  const isCRM = issue.template?.isCRM;
                  let IssueCard = isCRM ? CRMCard : SimpleCard;
                  return (
                    <IssueCard
                      key={issue._id}
                      issue={issue}
                      statusOptions={statusOptions}
                      onIssueUpdate={(newIssue) => {
                        setIssueDictionary({
                          ...issueDictionary,
                          [newIssue._id]: newIssue,
                        });
                      }}
                      onIssueDelete={(deletedIssueId) => {
                        refetchIssues(currentPage);
                      }}
                    />
                  );
                })}
              </Grid>
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, page) => refetchIssues(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </LoadingHandler>
          </StandardAppContainerRounded>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileIssueKanbanView;