import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../../helpers/Api";

import { useParams, useHistory } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import ProfileAppbar from "../profile/profile.appbar";
import ListPaperCard from "./list.item.card";
import Kanban from "../styled/Kanban/Kanban";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DoneIcon from "@material-ui/icons/Done";
import TableChartIcon from "@material-ui/icons/TableChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styled from "styled-components";
import RateReviewIcon from "@material-ui/icons/RateReview";
import IssueTemplateViewTable from "./issue.template.view.table";
import IssuesTable from "./issue.table";
import arrayToReducer from "../../helpers/arrayToReducer";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import { IconButton } from "@material-ui/core";
import FilterDialog from "./filter.dialog";
// import IssueGraph from "./issue.graph";
import IssueGraph from "./issue.graph";
import { MarkerType } from "react-flow-renderer";
import differenceInDays from "date-fns/differenceInDays";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import SelectTemplate from "./select.template.dialog";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import randomColor from "randomcolor";
import AnalyticsCard from "./issueAnalytics/AnalyticsCard";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import { setupAnalytics } from "./issueAnalytics/analyticsFns";
import { Box, MenuItem } from "@mui/material";
import KanbanView from "./issue.kanban.view";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FormBox from "../styled/generic/FormBox";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import Button from "../styled/generic/Button";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import TextField from "../styled/generic/TextField";
import DrawerContainer from "../styled/generic/DrawerContainer";
import IssueEditDrawer from "./issue.edit.drawer";
import { LoadingButton } from "@mui/lab";
import HorizBox from "../styled/generic/HorizBox";
import { ChatOutlined } from "@material-ui/icons";
import { UploadOutlined } from "@mui/icons-material";
import * as XLSX from "xlsx";
import Select from "../styled/generic/Select";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import ListingPicker from "../styled/generic/ListingPicker";
import dayjs from "dayjs";
import DatePicker from "../styled/generic/DatePicker";

function IssueTemplateView() {
  const history = useHistory();
  //const classes = useStyles();
  const { templateId, profileId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [template, setTemplate] = useState(null);
  const [templateDict, setTemplateDict] = useState({});

  const [issues, setIssues] = useState([]);
  const [issueIds, setIssueIds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [gotData, setGotData] = useState(false);
  const [finalStates, setFinalState] = useState([]);
  const [originalIssues, setOriginalIssues] = useState([]);
  const [originalIssueIds, setOriginalIssueIds] = useState([]);
  const [originalIssueDict, setOriginalIssueDict] = useState({});

  const [updateStatus, setUpdateStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [graphOpen, setGraphOpen] = useState(false);

  const [creatingTicket, setCreatingTicket] = useState(false);
  const [issueToUpdate, setIssueToUpdate] = useState(null);

  const [rowStart, setRowStart] = useState(0);
  const [rowEnd, setRowEnd] = useState(0);
  const [rows, setRows] = useState([]);

  const [listings, setListings] = useState([]);
  const [listing, setListing] = useState([]);
  const [selectedListing, setSelectedListing] = useState();

  const getData = async () => {
    try {
      setLoading(true);
      const res = await Api.post("issue/template/getTemplateDetail", {
        template: templateId,
      });
      if (res?.data) {
        const { template, issues } = res?.data;
        console.log(' getData, issues are the issues ',issues)

        setTemplate(template);
        let newTempDict = {};
        newTempDict[templateId] = template;
        setTemplateDict(newTempDict);
        const resIssues = issues?.length > 0 ? issues : [];
        const { newDict, idArr } = arrayToReducer(resIssues);
        setIssueIds(idArr);
        setIssues(resIssues);
        setOriginalIssueIds(idArr);
        setOriginalIssueDict(newDict);
        setOriginalIssues(resIssues);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (templateId) {
      getData();
    }
  }, [templateId]);

  useEffect(() => {
    if (searchQuery) {
      let temp = originalIssues.filter((issue) => {
        let title = (issue?.title).toLocaleLowerCase();
        return title.includes(searchQuery.toLocaleLowerCase());
      });
      setIssues([...temp]);
      let newIssueIds = temp.map((i) => i?._id);

      setIssueIds(newIssueIds);
    } else if (gotData) {
      setIssues([...originalIssues]);
      setIssueIds(originalIssueIds);
    }
    // if (gotData) {
    //   setLoading(false);
    // }
    // if(toggleButtonView)
    // setLoading(false);
  }, [searchQuery]);

  const getListings = async () => {
    try {
      let _rows = [];

      const res = await Api.post("/listing/getListingsBySearchAry", {
        template: templateId,
      });
      if (res?.data) {
        setListings(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (templateId) {
      getListings();
    }
  }, [templateId]);

  const [showIssueEditDrawer, setShowIssueEditDrawer] = useState(false);
  const [excelData, setExcelData] = useState();
  const [sheets, setSheets] = useState();
  const [selectedSheet, setSelectedSheet] = useState("");
  const [selectedSheetData, setSelectedSheetData] = useState();
  const [columns, setColumns] = useState([]);
  const [listingColumn, setListingColumn] = useState();
  const [listingOptions, setListingOptions] = useState([]);

  const [primaryPhoneColumn, setPrimaryPhoneColumn] = useState();
  const [otherPhoneColumns, setOtherPhoneColumns] = useState([]);
  const [primaryEmailColumn, setPrimaryEmailColumn] = useState();
  const [otherEmailColumns, setOtherEmailColumns] = useState([]);
  const [primaryClientUsernameColumn, setPrimaryClientUsernameColumn] =
    useState();

  const [otherClientUsernameColumns, setOtherClientUsernameColumns] = useState(
    []
  );

  const createApi = async () => {
    setCreatingTicket(true);
    const issueShared = template?.issueShared || [];
    const pipeline = template?.pipeline;
    const userProfileId = user?.profile;
    const sharedSet = new Set([...issueShared, profileId, userProfileId]);

    let issueObject = {
      user: user._id,
      template: templateId,
      profile: profileId,
      shared: Array.from(sharedSet),
    };

    if (pipeline?.length > 0) {
      issueObject = { ...issueObject, status: pipeline[0]?._id };
    }

    const res = await Api.post("issue/create", issueObject);
    const data = res?.data;
    const issueId = data?._id;
    const path = "/issue/edit/" + issueId;
    history.push(path);
  };

  const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);

  useEffect(() => {
    if (selectedSheet) {
      const sheet = excelData?.Sheets[selectedSheet];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setSelectedSheetData(sheetData);
    }
  }, [selectedSheet]);

  useEffect(() => {
    let _rows = [];
    if (selectedSheetData?.length > 0) {
      for (let i = rowStart; i <= rowEnd; i++) {
        let row = selectedSheetData[i];
        if (row) {
          _rows.push(row);
        }
      }
    }
    console.log("_rows: ", _rows);
    console.log("selectedSheetData: ", selectedSheetData);
    setRows(_rows);
  }, [rowStart, rowEnd, selectedSheetData]);

  useEffect(() => {
    if (rows?.length > 0) {
      console.log("Rows: ", rows);
      let cols = [];
      let row = rows[0];
      for (let key in row) {
        if (String(key).trim() !== "") {
          cols.push(key);
        }
      }
      setColumns(cols);
    }
  }, [rows]);

  useEffect(() => {
    let options = [];
    // Get value of listingColumn from all rows
    if (listingColumn) {
      rows.forEach((row) => {
        let value = row[listingColumn];
        if (value && String(row).trim() !== "" && !options.includes(value)) {
          options.push(value);
        }
      });
    }

    setListingOptions(options);
  }, [listingColumn]);

  const data = [
    { name: "Start", value: 2 },
    { name: "Step1", value: 4 },
    { name: "Success", value: 1 },
    { name: "Failure", value: 1 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [graphData, setGraphData] = useState([]);

  const getAnalytics = async () => {
    try {
      const { data } = await Api.post("/issue/analytics", {
        templateId: templateId,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      });

      console.log("Analytics data: ", data);

      setGraphData(
        data.map((item) => {
          return {
            name: template?.pipeline?.find((p) => p._id === item?._id)?.text,
            value: item?.count || 0,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [startDate, endDate, templateId]);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={template?.title || "View Template"}
      loading={loading}
      appBarActions={
        <HorizBox>
          <IconButton color="primary">
            <ChatOutlined />
          </IconButton>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setGraphOpen(true)}
            sx={{
              width: "120px",
            }}
          >
            Graph
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setUploadDrawerOpen(true)}
            sx={{
              width: "120px",
            }}
            startIcon={<UploadOutlined />}
          >
            Upload
          </Button>
          <LoadingButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={createApi}
            loading={creatingTicket}
            sx={{
              width: "120px",
            }}
          >
            Ticket
          </LoadingButton>
        </HorizBox>
      }
    >
      <KanbanView
        template={template}
        issueIds={issueIds}
        issueDictionary={originalIssueDict}
        setIssueDictionary={setOriginalIssueDict}
      />

      {/* <FilterDialog
        open={openFilterDialog}
        setOpen={setOpenFilterDialog}
        headers={headers}
        conditions={conditions}
        setConditions={setConditions}
        filterResults={filterResults}
      /> */}

      <IssueEditDrawer
        open={showIssueEditDrawer}
        setOpen={setShowIssueEditDrawer}
        issue={issueToUpdate}
      />

      <DrawerContainer open={graphOpen} setOpen={setGraphOpen} title="Graph">
        <HorizBox>
          <DatePicker
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
            label="Start Date"
          />
          <DatePicker
            value={endDate}
            onChange={(newDate) => setEndDate(newDate)}
            label="End Date"
          />
        </HorizBox>
        <PieChart width={400} height={400}>
          <Pie
            data={graphData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, value }) => `${name} (${value})`}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </DrawerContainer>

      <DrawerContainer
        open={uploadDrawerOpen}
        setOpen={setUploadDrawerOpen}
        title="Upload Excel"
        width="80vw"
      >
        {!excelData && (
          <Box>
            <Typography variant="body1" gutterBottom>
              Upload Excel file to create issues
            </Typography>
            <input
              type="file"
              hidden
              id="fileUpload"
              accept=".xls , .xlsx , .xlsv"
              onChange={(e) => {
                // Check if the file is an excel file
                const file = e.target.files[0];
                const reader = new FileReader();

                reader.onload = (event) => {
                  const binaryStr = event.target.result;
                  const workbook = XLSX.read(binaryStr, { type: "binary" });

                  console.log("Workbook: ", workbook);

                  setExcelData(workbook);
                  setSheets(workbook.SheetNames || []);
                };

                reader.readAsBinaryString(file);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              component="label"
              onClick={(e) => {
                document.getElementById("fileUpload").click();
              }}
            >
              Upload
            </Button>
          </Box>
        )}

        {excelData && (
          <>
            {/* SELECT EXCEL SHEET */}
            <FormBox label="Select Excel Sheet">
              <Select
                value={selectedSheet}
                onChange={(e) => setSelectedSheet(e.target.value)}
                fullWidth={true}
                displayEmpty
              >
                <MenuItem value="">Select</MenuItem>
                {sheets?.map((sheet) => (
                  <MenuItem value={sheet}>{sheet}</MenuItem>
                ))}
              </Select>
            </FormBox>
            {/* SELECT ROW RANGE */}
            {selectedSheet && (
              <>
                <FormBox label="Enter Row Start">
                  <TextField
                    fullWidth
                    placeholder="Enter"
                    type="number"
                    value={rowStart}
                    onChange={(e) => setRowStart(e.target.value)}
                  />
                </FormBox>
                <FormBox label="Enter Row End">
                  <TextField
                    fullWidth
                    placeholder="Enter"
                    type="number"
                    variant="outlined"
                    value={rowEnd}
                    onChange={(e) => setRowEnd(e.target.value)}
                  />
                </FormBox>
              </>
            )}
            {/* SELECT LISTING COLUMN */}
            {rows.length > 0 && (
              <>
                <FormBox label="Select Listing Column">
                  <Select
                    value={listingColumn}
                    onChange={(e) => setListingColumn(e.target.value)}
                    fullWidth={true}
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>

                <Box>
                  <Grid container spacing={2}>
                    {listingOptions.length > 0 &&
                      listingOptions?.map((item) => (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="body1">{item}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <ListingPicker
                              selectedListing={selectedListing}
                              setSelectedListing={setSelectedListing}
                            />
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </Box>

                {/* SELECT PRIMARY PHONE NUMBER COLUMN */}
                <FormBox label="Select Primary Phone Number Column">
                  <Select
                    value={primaryPhoneColumn}
                    onChange={(e) => setPrimaryPhoneColumn(e.target.value)}
                    fullWidth={true}
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
                {/* SELECT OTHER PHONE NUMBER COLUMNS */}
                <FormBox label="Select Other Phone Number Columns">
                  <Select
                    value={otherPhoneColumns}
                    onChange={(e) => setOtherPhoneColumns(e.target.value)}
                    fullWidth={true}
                    displayEmpty
                    multiple
                  >
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
                {/* SELECT PRIMARY EMAIL COLUMN */}
                <FormBox label="Select Primary Email Column">
                  <Select
                    value={primaryEmailColumn}
                    onChange={(e) => setPrimaryEmailColumn(e.target.value)}
                    fullWidth={true}
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
                {/* SELECT OTHER EMAIL COLUMNS */}
                <FormBox label="Select Other Email Columns">
                  <Select
                    value={otherEmailColumns}
                    onChange={(e) => setOtherEmailColumns(e.target.value)}
                    fullWidth={true}
                    displayEmpty
                    multiple
                  >
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
                {/* SELECT PRIMARY CLIENT USERNAME COLUMN */}
                <FormBox label="Select Primary Client Username Column">
                  <Select
                    value={primaryClientUsernameColumn}
                    onChange={(e) =>
                      setPrimaryClientUsernameColumn(e.target.value)
                    }
                    fullWidth={true}
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
                {/* SELECT OTHER CLIENT USERNAME COLUMNS */}
                <FormBox label="Select Other Client Username Columns">
                  <Select
                    value={otherClientUsernameColumns}
                    onChange={(e) =>
                      setOtherClientUsernameColumns(e.target.value)
                    }
                    fullWidth={true}
                    displayEmpty
                    multiple
                  >
                    {columns?.map((col) => (
                      <MenuItem value={col}>{col}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
              </>
            )}
          </>
        )}

        <DuoButtonGroup
          primaryButtonText="Upload"
          secondaryButtonText="Cancel"
          primaryButtonAction={() => {
            console.log("Upload");
          }}
          secondaryButtonListener={() => {
            setUploadDrawerOpen(false);
          }}
        />
      </DrawerContainer>
    </StandardContainer>
  );
}

export default IssueTemplateView;
