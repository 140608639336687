import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Avatar,
  AvatarGroup,
  IconButton,
  Tooltip,
  Grid,
  Divider,
} from "@mui/material";
import {
  WorkOutline as WorkOutlineIcon,
  Business as BusinessIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  CalendarToday as CalendarIcon,
  Label as LabelIcon,
} from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';
import Api from "../../helpers/Api";

const SimpleCard = ({ issue, statusOptions, onIssueUpdate, onIssueDelete }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const isCreator = user?._id === issue?.user?._id;

  const getPriorityInfo = (priority) => {
    const priorityMap = {
      1: { color: "#757575", text: "Very Low" },
      2: { color: "#4CAF50", text: "Low" },
      3: { color: "#FFC107", text: "Medium" },
      4: { color: "#FF9800", text: "High" },
      5: { color: "#F44336", text: "Urgent" },
    };
    return priorityMap[priority] || priorityMap[1];
  };

  const truncateHtml = (html, maxLength) => {
    if (!html) return "";
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    let text = tmp.textContent || tmp.innerText || "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : html;
  };

  const updateIssue = async (statusId) => {
    try {
      const res = await Api.post("issue/updateFromKanban", {
        issueId: issue?._id,
        status: statusId,
      });
      if (res?.success) {
        dispatch({
          type: "AddApiAlert",
          payload: { success: true, message: "Status updated successfully" },
        });
        onIssueUpdate({
          ...issue,
          status: statusOptions.find((status) => status._id === statusId),
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: { success: false, message: res?.message || "Something went wrong" },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Something went wrong" },
      });
    }
  };

  const deleteIssue = async () => {
    try {
      const res = await Api.post("issue/delete", { issueId: issue?._id });
      if (res) {
        dispatch({
          type: "AddApiAlert",
          payload: { success: true, message: "Issue deleted successfully" },
        });
        onIssueDelete(issue?._id);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: { success: false, message: res?.message || "Something went wrong" },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Something went wrong" },
      });
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.3s ease',
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid #e0e0e0',
        }}
      >
        <CardContent sx={{ flexGrow: 1, cursor: 'pointer', p: "16px", paddingBottom: "0px" }} onClick={() => history.push(`/issue/view/${issue?._id}`)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Avatar
                src={issue?.user?.displayPicture?.thumbUrl || issue?.user?.displayPicture?.url}
                alt={issue?.user?.displayName}
                sx={{ width: 40, height: 40 }}
              >
                {issue?.user?.displayName?.charAt(0)}
              </Avatar>
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', lineHeight: 1.2 }}>
                  {issue?.user?.displayName || "N/A"}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {moment(issue?.createdAt).format("DD MMM YYYY")}
                </Typography>
              </Box>
            </Box>
            <Box>
              {isCreator && (
                <Tooltip title="Edit Issue">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/issue/edit/${issue?._id}`);
                    }}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {/* // <PopupMenuList
              //   appearContent={
              //     <IconButton onClick={(e) => e.stopPropagation()} size="small">
              //       <MoreVertIcon fontSize="small" />
              //     </IconButton>
              //   }
              //   actions={statusOptions.map((status) => ({
              //     title: status?.text,
              //     action: (e) => {
              //       e.stopPropagation();
              //       updateIssue(status?._id);
              //     },
              //   }))}
              //   secondaryActions={[
              //     {
              //       title: "Delete",
              //       action: (e) => {
              //         e.stopPropagation();
              //         setShowConfirmationDialog(true);
              //       },
              //     },
              //   ]}
              // /> */}
            </Box>
          </Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              lineHeight: 1.
            }}
          >
            {issue?.title || "Untitled"}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{
            mb: 2,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: 1.5,
          }}>
            {ReactHtmlParser(truncateHtml(issue?.description, 150))}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <WorkOutlineIcon sx={{ mr: 1, fontSize: 18, color: 'primary.main' }} />
                <Typography variant="body1" color="text.primary" noWrap>
                  {issue?.project?.displayName || "N/A"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BusinessIcon sx={{ mr: 1, fontSize: 20, color: 'primary.main' }} />
                <Typography variant="body2" color="text.primary" noWrap>
                  {issue?.organization?.displayName || "N/A"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {issue?.labels?.length > 0 && (
            <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {issue?.labels?.slice(0, 3).map((item) => (
                <Chip
                  key={item.id}
                  icon={<LabelIcon sx={{ fontSize: 16 }} />}
                  label={item?.name}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    '& .MuiChip-label': { px: 1 },
                  }}
                />
              ))}
              {issue?.labels?.length > 3 && (
                <Chip
                  label={`+${issue?.labels?.length - 3}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    '& .MuiChip-label': { px: 1 },
                  }}
                />
              )}
            </Box>
          )}
        </CardContent>
        <Divider />
        <Box sx={{ p: 1, backgroundColor: 'background.default', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Priority">
              <Chip
                label={getPriorityInfo(issue?.priority).text}
                size="small"
                sx={{
                  backgroundColor: getPriorityInfo(issue?.priority).color,
                  color: 'white',
                  fontWeight: 'bold',
                  mr: 1,
                }}
              />
            </Tooltip>
            <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 28, height: 28, fontSize: 12 } }}>
              {issue?.assigned?.map((userData, i) => (
                <Tooltip key={i} title={userData?.parent?.displayName || 'No Name'} arrow>
                  <Avatar
                    src={userData?.parent?.displayPicture?.thumbUrl || userData?.parent?.displayPicture?.url}
                    variant="circular"
                  >
                    {userData?.parent?.displayName?.charAt(0)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
          <Chip
            label={issue?.status?.text}
            size="small"
            sx={{
              backgroundColor: issue?.status?.color || "#E48900",
              color: "white",
              fontWeight: 'bold',
            }}
          />
        </Box>
      </Card>
      {/* // <ConfirmationDialog
      //   title="Delete Ticket"
      //   message="Are you sure you want to delete this ticket? This action cannot be undone."
      //   successButtonText="Yes"
      //   cancelButtonText="Cancel"
      //   successListener={() => {
      //     deleteIssue();
      //     setShowConfirmationDialog(false);
      //   }}
      //   cancelListener={() => setShowConfirmationDialog(false)}
      //   open={showConfirmationDialog}
      //   setOpen={setShowConfirmationDialog}
      // /> */}
    </Grid>
  );
};

export default SimpleCard;